 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="100px">
			<el-form-item class="el_form_item" label="货主手机号">
				<el-input class="el_input" v-model="form.shipper_tel" placeholder="货主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车队长手机号">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="车队长手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="货主手机号">
					<template slot-scope="scope">
						<div>{{scope.row.shipper_tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货主公司名称">
					<template slot-scope="scope">
						<div>{{scope.row.company_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车队长手机号">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_owner_tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车队长姓名">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_owner_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="添加时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.time_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="110">
					<template slot-scope="scope">
					
						<el-button @click="del(scope.row)" size="mini" type="text" style="margin:0">删除</el-button>
					
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

      <add :is_show="agent_add.is_show" @refresh='get_page_data'></add>
	</div>
    
</template>

<script>
import add from './add.vue'
	export default {
       components:{
			add,
		},
		data() {
			return {


				//搜索条件
				form: {
					shipper_tel:'',//货主手机号
					truck_owner_tel:'',//车队长手机号
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
                agent_add:{
                    is_show:0,
                },
			}
		},
		created() {

			//读取页面数据
			this.get_page_data()
		},
		methods: {
			del(item){
				//询问
				this.$my.other.confirm({
					content:"是否确认删除此白名单?",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_driver',
								ctr:'truck_owner_allow_del',
								id:item.id
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								//读取页面数据
								this.get_page_data()
							}
						})
					}
				});
			},
            open_add_view(){
                this.agent_add.is_show++
            },

			//清空查询条件
			ser_para_clear(){
				this.form={
					bl_name:'',//线路名称
					shipper_tel:'',//货主联系电话
					shipper_user_name:'',//货主联系人
					shipper_company_name:'',//货主公司名称
					cargo_name:'',//货物名称
					case_city:null,//发货省市区
					aim_city:null,//卸货省市区
					is_need_weight:'',//是否必填货物重量(1:是,2:否)
					is_need_volume:'',//是否必填货物体积(1:是,2:否)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					mark:'',//备注
					item_name:'',//项目名称
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
                    take_over_control:1,
					data:{
						mod:'truck_driver',
						ctr:'truck_owner_allow_list',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false
                        if(data.code !=0){

                        }
						//总数
						this.list.total=data.msg.max
						//预处理
						for(let item of data.msg.list){
                          	item.time_text=this.$my.other.totime(item.time);
					
						}
						
						//渲染
						this.list.data=data.msg.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 190px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>