<template>
	<el-dialog top="3vh" title="添加车队长白名单" width="500px" :visible.sync="is_show_in_page" :close="close">
		<el-form status-icon label-position="left" label-width="100px">
            <el-form-item label="货主手机号" >
				<el-input class="el_input" v-model="form.shipper_tel"></el-input>
			</el-form-item>
             <el-form-item label="车队长手机号" >
				<el-input class="el_input" v-model="form.truck_owner_tel"></el-input>
			</el-form-item>
		</el-form>
		<div style="text-align:center">
			<el-button type="primary" @click="add_view_sub">提交</el-button>
		</div>
	</el-dialog>

</template>

<script>
	export default {
		props:{
			is_show:Number,
            id:String
		},
		data() {
			return {
                form:{
                    shipper_tel:'',//货主手机号
                    truck_owner_tel:'',//车队长手机号
                },
                is_show_in_page:false
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
				}
			}
		},
		methods: {
            close(){
                this.form={
                    shipper_tel:'',//货主手机号
                    truck_owner_tel:'',//车队长手机号
                }
            },
            add_view_sub(){
                this.$my.net.req({
                    data:{
                        mod:'truck_driver',
                        ctr:'truck_owner_allow_add',
                        ...this.form
                    },callback:(data)=>{
                        this.$my.other.msg({
                            type:'success',
                            str:'添加成功'
                        });
                        this.is_show_in_page = false
                        this.close()
                        this.$emit('refresh')
                    }
                })
            }
		}
	}
</script>

<style lang="scss" scoped>

	.el_input{
		width:94%;
       
	}
</style>